import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* First Section */}
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/indicators">Indicators</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/Privacy">Privacy Policy</Link></li>
            <li><Link to="/TermsConditions">Terms & Disclaimer</Link></li>
            
          </ul>
        </div>

        {/* Second Section */}
        <div className="footer-section">
          <h3>Follow Us</h3>
          <ul>
            <li><a href="https://x.com/CIR9_Official" target="_blank" rel="noopener noreferrer">X</a></li>
            <li><a href="https://www.instagram.com/cir_9_official/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>

        {/* Third Section */}
        <div className="footer-section">
          <h3>Contact</h3>
          <ul>
            <li><a href="mailto:support@cir9.com">support@cir9.com</a></li>
            <li className="disclaimer">
            Disclaimer: Trading cryptocurrencies carries a high level of risk. Please trade responsibly. Learn more in our <a href="/TermsConditions">Terms & Disclaimer</a>.

            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2025 CIR-9. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
